import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from "react-router-dom";
import { selectAuthState, selectUser } from '../auth/authSlice';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { AuthState } from '@aws-amplify/ui-components';
import { Signout } from '../signout/Signout';

export function NavigationBar() {
  const user = useSelector(selectUser);
  const authState = useSelector(selectAuthState);
  const location = useLocation();

  const currentpath = location.pathname;

  let fluid = false;
  let brandText = 'CSO Household Budget Survey Portal';
  const isUserSignedIn = (authState === AuthState.SignedIn && user ) ? true : false;

  if(currentpath.includes('/terms')) {
    brandText = "CSO Household Budget Survey Portal Terms And Conditions"
  } else if (currentpath.includes('/home')) {
    brandText = "Home"
    fluid = true;
  }
    else if (currentpath.includes('/changepwd')) {
      brandText = "Change Password"
      fluid = true;
  }

  const SignedInUserInfo = (props) => {

    const signedInAs = 'Signed in as user:'

    return (
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          {signedInAs} <Link to="/home/profile">{props.user.username}</Link>&nbsp;
        </Navbar.Text>
        &nbsp;<Signout/>
      </Navbar.Collapse>
    )
  };

  const Contacts = (props) => {
      return (
        <div>
          <Link to="/home/contacts" style={{ color: '#FFF' }}>Contact Us</Link>&nbsp;
        </div>
      );
  }

  return (
    <Container fluid={fluid} className='pt-4 pr-0 pl-0'>
      <Navbar bg="csonav" variant="dark">
          <Navbar.Brand>
            <Link to="/home" style={{ color: '#FFF' }}>{brandText}</Link>
          </Navbar.Brand>
          &nbsp;
          <Navbar.Brand>
          {isUserSignedIn && <Link to="/home/profile" style={{ color: '#FFF' }}>User Profile</Link>}
          </Navbar.Brand>
          <Navbar.Brand>
          {isUserSignedIn && <Contacts user={user} />}
          </Navbar.Brand>
        <Navbar.Toggle />
        {isUserSignedIn && <SignedInUserInfo user={user}/>}
        <Nav className="mr-auto">
          <Nav.Link href="#home"></Nav.Link>
        </Nav>
      </Navbar>

    </Container>
  );
};
