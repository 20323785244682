import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { selectAuthState } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';
import { AuthState } from '@aws-amplify/ui-components';

export function PrivateRoute({ children, ...rest }) {
    const authState = useSelector(selectAuthState);
    const isAuthenticated = ( authState === AuthState.SignedIn ) ? true : false;

    return (
        <Route
            {...rest}
            render={({ location }) =>
            isAuthenticated ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            )
            }
        />
    );
}