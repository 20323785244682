import React from 'react';
import {
  Switch,
  useRouteMatch
} from "react-router-dom";
import { UserProfile } from '../userprofile/UserProfile';
import { HomePage } from '../homepage/HomePage';
import { PrivateRoute } from '../../components/PrivateRoute';
import { ChangePassword } from '../changepassword/ChangePassword';
import { Contacts } from '../contacts/Contacts';
import { FileUpload } from '../fileupload/fileUpload';

export function Home() {

  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}/profile`}>
          <UserProfile/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/upload`}>
          <FileUpload/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/changepwd`}>
          <ChangePassword/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/contacts`}>
          <Contacts/>
        </PrivateRoute>
        <PrivateRoute path={path}>
          <HomePage/>
        </PrivateRoute>
      </Switch>
    </div>
  );
}
