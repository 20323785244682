import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '@aws-amplify/ui-components';
import { listSurveyData, unlistSurveyData } from '../surveydata/surveyDataSlice';
import { listSurveys, selectSurveys } from '../surveylist/surveyListSlice';
import { selectAuthState } from '../auth/authSlice';
import { SurveyData } from '../surveydata/SurveyData';

export function HomePage() {

    const dispatch = useDispatch();
    const surveys = useSelector(selectSurveys);
    const authState = useSelector(selectAuthState);

    const surveysBool = surveys && surveys.length > 0

    React.useEffect(() => {

        if(authState === AuthState.SignedIn){
            dispatch(unlistSurveyData());
            dispatch(listSurveys());
        }

    }, [authState, dispatch]);

     React.useEffect(() => {
        if(authState === AuthState.SignedIn){

            surveys.map((surveyRow, key) => { return dispatch(listSurveyData(surveyRow.SK.split('#')[1]))})

        }    
    }, [surveysBool, authState, dispatch]);


  return (
        <SurveyData />
  );
}
