import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './Footer.module.scss';

export function FooterPadding() {
    //const location = useLocation();
    //const currentpath = location.pathname;

    // let fluid = false;
    // if(currentpath.includes('/home')) {
    //     fluid = true;
    // } 

    return (
        <Container className={styles.containerElement}>

        </Container>

     )

}