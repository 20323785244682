import {LAUNCH_URL } from '../../config/service';

export function LaunchSurveyData ([csonbr, username, pin])  {

    //const app = require('@cso-portal/server');
    const append = csonbr + "&pin=" + pin;
    const url = LAUNCH_URL + "/metadata?csonbr=" + append;
    const collection_exercise_sid = "b6ueqr02ym8r4adv5ldb" + btoa(username);

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      }

    function randomNumericString(length) {
    var randomChars = '0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
    }

    fetch(url).then(function(response) { 
        return response.json()
    }).then(function(json) {
        var bodyFormData = new FormData()
        json.map((item, key) => {
            return bodyFormData.append(item.name, item.default)
         })
         bodyFormData.append("ru_ref", csonbr);
         bodyFormData.append("pin", pin);
         bodyFormData.set("user_id", username);
         bodyFormData.append("language_code", "en");
         bodyFormData.append("collection_exercise_sid", collection_exercise_sid);
         bodyFormData.append("case_id", uuidv4());
         bodyFormData.append("response_id", randomNumericString(16));
         bodyFormData.append('exp', '1800');
         bodyFormData.append('account_service_url', '');
         bodyFormData.append('account_service_log_out_url', '');
         bodyFormData.append('action_portal', 'Open Survey');
         return bodyFormData
    }).then(function(data) {
        const URLdata = new URLSearchParams(data);
        return URLdata
    }).then(function(URLdata){

        //fetch('http://localhost:8000/', {
        fetch(LAUNCH_URL, {
        method: 'POST',
        //xmlns: 'http://www.w3.org/1999/html',
        body: URLdata
        //, mode: 'no-cors'
        //, credentials: 'include'
        , redirect: "follow"
        //, headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        })
        .then(function(response) {
            return response.json()
        }).then(function(json) {
            window.open(json.launchURL, '_blank');
        }).catch(error => {
        console.error('Error:', error);
        });
    }).catch((error) => console.log(error))

}