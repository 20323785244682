import React from 'react';
import logo from '../../assets/img/CSO_Logo_.png'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import { useLocation } from 'react-router-dom';
import headerStyles from './Header.module.scss';

export function Header() {
    const location = useLocation();
    const currentpath = location.pathname;

    let fluid = false;
    if(currentpath.includes('/home')) {
        fluid = true;
    } 

    return (
        <Container fluid={fluid} >
            <Row className='pt-3 pb-2'>
                <Col>
                    {/* TODO: Should be Link from router */}
                    <a href="/"><Image src={logo}  className={headerStyles.logoFullWidth} alt="Central Statistics Office" /></a>  
                    <span style={{float:"right"}}>
                        <ListGroup horizontal>
                            <ListGroup.Item className='border-none'>
                                <a href="https://www.youtube.com/user/CSOIrelandMedia" title="Follow us on YouTube" target="_blank" rel="noreferrer"><i className="fa fa-youtube-play fa-2x"></i></a>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-none'>
                                <a href="https://twitter.com/csoIreland" title="Follow us on Twitter" target="_blank" rel="noreferrer"><i className="fa fa-twitter fa-2x"></i></a>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-none'>
                                <a href="https://www.facebook.com/CSOIreland" title="Like us on Facebook" target="_blank" rel="noreferrer"><i className="fa fa-facebook-official fa-2x"></i></a>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-none'>
                                <a href="https://data.cso.ie/" title="Subscribe to RSS feeds" target="_blank" rel="noreferrer"><i className="fa fa-rss fa-2x"></i></a>
                            </ListGroup.Item>
                        </ListGroup>
                    </span>
                </Col>
            </Row>
        </Container>
    );
}