import React from "react";
import { Form, Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useLocation } from "react-router-dom";
import { selectUser } from '../auth/authSlice';
import { useSelector } from 'react-redux';
import { selectSurveyData } from '../surveydata/surveyDataSlice';
import { Uploader } from './uploaderComponent';


export const FileUpload = () => {

    const location = useLocation();
    const selectedSurvey = location.pathname.split('upload/')[1]
    const user = useSelector(selectUser)
    const surveys = useSelector(selectSurveyData);

    function byKeyPK(arr, key) {
      for ( var i=0, L=arr.length; i<L; i++ ) {
        if ( arr[i].survey_id === key ) {
          return arr[i].PK;
        }
      }
    }

    function byKeyName(arr, key) {
      for ( var i=0, L=arr.length; i<L; i++ ) {
        if ( arr[i].survey_id === key ) {
          return arr[i].author_title;
        }
      }
    }

  return (
    <div>
      <Container className="pt-5">
        <Card>
          <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}><h3>Upload Files</h3></Card.Header>
          <Card.Body>
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Survey Title
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" readOnly defaultValue={byKeyName(surveys, selectedSurvey)}/>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                    Files to Attach
                </Form.Label>&nbsp;&nbsp;&nbsp;
              </Form.Group>
                <Uploader surveyName={selectedSurvey} survey={byKeyPK(surveys, selectedSurvey)} org={user.cso_identifier} username={user.username}/>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div> 
  );
}