import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { useLocation } from 'react-router-dom';
import styles from './Footer.module.scss';

export function Footer() {
    const location = useLocation();
    const currentpath = location.pathname;

    let fluid = false;
    if(currentpath.includes('/home')) {
        fluid = true;
    } 

    let containerClass = 'container-fluid bg-light relative-bottom'

    return (

        <Container fluid={fluid} className={containerClass}>
            <Row className='pt-10 pb-20'>
                    <Col>
                        <div className={styles.footerTitle}>
                            Contact:
                        </div>
                        <div className={styles.footText}>
                            An Phríomh-Oifig Staidrimh
                        </div>
                        <div className={styles.footContact}>
                            Bóthar na Sceiche Airde,
                        </div>
                        <div className={styles.footContact}>
                            Corcaigh, Éire, T12 X00E
                        </div>
                        <div className={styles.footText}>
                            Central Statistics Office
                        </div>
                        <div className={styles.footContact}>
                            Skehard Road, Cork,
                        </div>
                        <div className={styles.footContact}>
                            Ireland, T12 X00E
                        </div>
                        <div className={styles.footerTitle}>           
                        </div>
                    </Col>
            
                    <Col>
                        
                        <div className={styles.footerTitle}>
                        </div>
                        <div className={styles.footerTitle}>
                        </div>
                        <div className={styles.footerTitle}>
                        </div>
                        <br></br>
                        <div className={styles.footContact}>
                        <i className="fa fa-phone footer-li social"></i>&nbsp;
                            <a href="tel:+353214535000" target="_blank" rel="noreferrer">
                                +353 21 453 5000
                            </a>
                        </div>
                        <div className={styles.footContact}>
                        <i className="fa fa-envelope footer-li social"></i>&nbsp;
                            <a href="mailto:eolas@cso.ie" target="_blank" rel="noreferrer">
                                eolas@cso.ie
                            </a>
                        </div>
                        <div className={styles.footContact}>
                        <i className="fa fa-globe footer-li social"></i>&nbsp;
                            <a href="https://www.cso.ie/en/baile/" target="_blank" rel="noreferrer">
                                www.cso.ie/en/baile/
                            </a>
                        </div>
                        <br></br>
                        <div className={styles.footContact}>
                        <i className="fa fa-phone footer-li social"></i>&nbsp;
                            <a href="tel:+353214535000" target="_blank" rel="noreferrer">
                                +353 21 453 5000
                            </a>
                        </div>
                        <div className={styles.footContact}>
                        <i className="fa fa-envelope footer-li social"></i>&nbsp;
                            <a href="mailto:information@cso.ie" target="_blank" rel="noreferrer">
                                information@cso.ie
                            </a>
                        </div>
                        <div className={styles.footContact}>
                        <i className="fa fa-globe footer-li social"></i>&nbsp;
                            <a href="https://www.cso.ie" target="_blank" rel="noreferrer">
                                www.cso.ie
                            </a>
                        </div>
                        <br></br>
                    </Col>
                
                <Col>
                    <div className={styles.footerTitle}>
                        Links:
                    </div>
                    <div className={styles.footText}>
                    </div>
                    <div>
                        <a href="https://www.cso.ie/en/aboutus/whoweare/copyrightpolicy/" target="_blank" rel="noreferrer" className={styles.footContact}>
                            © 2020
                        </a>
                    </div>
                    <div>
                        <a href="https://www.cso.ie/en/freedomofinformation/" target="_blank" rel="noreferrer" className={styles.footContact}>
                            Freedom of Information
                        </a>
                    </div>
                    <div>
                        <a href="https://www.cso.ie/en/aboutus/lgdp/dataprotectionprivacytransparency/corporateprivacytransparencynotices/" target="_blank" rel="noreferrer" className={styles.footContact}>
                            Data Protection
                        </a>
                    </div>
                    <div>
                        <a href="https://www.cso.ie/en/aboutus/lgdp/dataprotectionprivacytransparency/websiteprivacystatement/" target="_blank" rel="noreferrer" className={styles.footContact}>
                            Privacy Statement
                        </a>
                    </div>
                    <div>
                        <a href="https://eforms.cso.ie/#" target="_blank" rel="noreferrer" className={styles.footContact}>
                            Surveys
                        </a>
                    </div>
                </Col>
                <Col>
                    <div className={styles.footerTitle}>
                        Media:
                    </div>
                    <div className={styles.footerTitle}>           
                    </div>
                    <div className={styles.footContact}>
                        Follow us on these links:
                    </div>
                    <div>
                        <ListGroup horizontal>
                            <ListGroup.Item className='border-none bg-light'>
                                <a href="https://www.youtube.com/user/CSOIrelandMedia" title="Follow us on YouTube" target="_blank" rel="noreferrer"><i className="fa fa-youtube-play fa-2x"></i></a>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-none bg-light'>
                                <a href="https://twitter.com/csoIreland" title="Follow us on Twitter" background-color="" target="_blank" rel="noreferrer"><i className="fa fa-twitter fa-2x"></i></a>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-none bg-light'>
                                <a href="https://www.facebook.com/CSOIreland" title="Like us on Facebook" target="_blank" rel="noreferrer"><i className="fa fa-facebook-official fa-2x"></i></a>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-none bg-light'>
                                <a href="https://data.cso.ie/" title="Subscribe to RSS feeds" target="_blank" rel="noreferrer"><i className="fa fa-rss fa-2x"></i></a>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </Container>
);
}