import React, { useState }  from 'react';
import { Button, Form } from 'react-bootstrap';
import { showError, showInfo } from '../messages/messagesSlice';
import { showSuccess} from '../messages/messagesSlice';
import { showWarning} from '../messages/messagesSlice';
import { selectUser } from '../auth/authSlice';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';

export const Uploader = (props) => {

  const [fileState, setFileState] = useState({});
  const [messageState, setMessageState] = useState('');
  const [commentState, setCommentState] = useState('');
  const [fileSelected, setFileSelected] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  let buttonText;


  const fileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv',
                      'application/pdf', 'application/zip', 'image/jpeg','image/png']

  if (commentState.trim() === '')
  {
    buttonText = "Upload"
  }
  else
  {
    buttonText = "Upload with comment"
  }

  const getFile = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
        let message = "Files: "
        for (let i = 0; i < files.length; i++) {
            if(i > 0 && i < files.length)
            {
                message = message + ", "
            }
            const file = files[i];
            const type = file.type;

            if(file.size === 0)
            {
                message = message + file.name + " is an empty file and cannot be uploaded."
                break;
            }
            else if (file.size > 10000000)
            {
                message = message + file.name + " is too big to be uploaded, file must be less than 10MB."
                break;
            }
            else if (fileTypes.includes(type))
            {
                message = message + file.name + ' selected successfully'
                if(i === files.length - 1)
                {
                  setFileState({ files })
                  setFileSelected(true)
                }
            }
            else 
            {
                message = message + file.name + ' is an invalid file type. Accepted file types are: .doc, .docx, .xls, .xlsx, .csv, .pdf, .png, .jpg, .jpeg, .zip'
            }
        }
        setMessageState(message)
    }
  };

  const getComment = (e) => {
    const comment = e.target.value;
    setCommentState(comment)
  };

  const uploadFile = (e) => {
    e.preventDefault();
    for (let i = 0; i < fileState.files.length; i++) {
      const file = fileState.files[i];
      setMessageState('Uploading...')
      const folder = 'quarantine/' + props.survey + '/ORG#' + props.org + '/' + props.username + '/'
      const fileNameLessSpaces = file.name.replace(/ /g, "_");
      const keyString = folder + fileNameLessSpaces
      const contentType = file.type

        const urlOptions = {
          params: {
            Key: keyString,
            Filename: file.name,
            Comment: commentState.trim()
          },
          headers: {
            'Content-Type': contentType
          }
        };

        API.get(`${SURVEYS_API_URL}/v1/surveys/s3/put`, urlOptions).then(res => {
            
            return res.data})
            .then(function(putURL) {

                fetch(putURL, {
                    method: 'PUT',
                    mode: 'cors',
                    body: file
                    }).then(function(res){

                        if(res.status === 200)
                        {
                          dispatch(showInfo({ "message": file.name + " is being scanned for viruses and malware..." }))
                          API.get(`${SURVEYS_API_URL}/v1/surveys/s3/scan`, urlOptions).then(function(scanRes){

                            if(scanRes.data.is_infected === true)
                            {
                              const dbOptions = {
                                params: {
                                  cso_id: 'ORG#' + props.org,
                                  survey_id: props.survey,
                                  username: user.username,
                                  comment: false,
                                  file_name: file.name,
                                  file_status: "infected/deleted"
                                }
                              };

                              API.get(`${SURVEYS_API_URL}/v1/surveys/s3/remove`, urlOptions)
                              dispatch(showWarning({ "message": file.name + " is infected and cannot be uploaded." }))
                              setMessageState('File Upload Failed.');
                              API.get(`${SURVEYS_API_URL}/v1/surveys/s3/record`, dbOptions)
                            }
                            else if(scanRes.data.is_infected === false)
                            {
                              const commentBool = commentState.trim() !== ""

                              const dbOptions = {
                                params: {
                                  cso_id: 'ORG#' + props.org,
                                  survey_id: props.survey,
                                  username: user.username,
                                  comment: commentBool,
                                  file_name: file.name,
                                  file_status: "clean/verified"
                                }
                              };

                              API.get(`${SURVEYS_API_URL}/v1/surveys/s3/copy`, urlOptions)
                              dispatch(showSuccess({ "message": file.name + " has been uploaded successfully." }))
                              setMessageState("File: " + file.name + " has been uploaded successfully.")
                              API.get(`${SURVEYS_API_URL}/v1/surveys/s3/record`, dbOptions);
                              if(commentBool)
                              {
                                API.get(`${SURVEYS_API_URL}/v1/surveys/s3/comment`, urlOptions).then(function(commentRes)
                                {
                                  if(commentRes.status === 201)
                                  {
                                    dispatch(showSuccess({ "message": "Your comment has been attached successfully." }))
                                  }
                                  else
                                  {
                                    dispatch(showWarning({ "message": "Something went wrong, comment failed to attach. " }))
                                  }
                                })
                              }
                            }
                            else 
                            {
                              API.get(`${SURVEYS_API_URL}/v1/surveys/s3/remove`, urlOptions)
                              dispatch(showError({ "message": "Process timed out, please try again."}))
                              setMessageState('File Upload Failed.');
                            }
                          })
                        }
                      })
                    .catch((error) => {
                      dispatch(showError({ "message": error.message }));
                    });

                    setFileState({});
                    setFileSelected(false);
                    setCommentState('');
            })
        }        
    };

    return (
      <Form.Group>
        <input
          id='upload-file'
          type='file'
          accept=".xls,.xlsx,.doc,.docx,.pdf,.csv,.zip,.png,.jpg,.jpeg"
          multiple="multiple"
          onChange={getFile}
        />
        <p>{messageState}</p>
        <Form.Group controlId="TextArea">
          <Form.Label>Comments (Optional)</Form.Label>
          <Form.Control
              as="textarea"
              rows={3}
              onChange={getComment}
              value={commentState}
           />
        </Form.Group>
        <div style={{float: "right"}} className="pt-2 pr-2">
          <Link to = {`/home`}>
            <Button
              >Back to home page
            </Button>
          </Link>
        </div>
        <div style={{float: "right"}} className="pt-2 pr-2">
    <Button variant='info' disabled={!fileSelected} onClick={uploadFile}>{buttonText}</Button>
        </div>
      </Form.Group>
      
    );
}