import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export function TermsPage() {
    return (
        <div>
        <Container>
            <Row className='pt-5'>
                <Col><ul>
                        <li>These are the terms and conditions for use of our online platform</li>&nbsp;
                        <li>By signing up, you confirm you have read and agree to these terms</li>&nbsp;
                        <li>This is the third term of use of our platform</li>&nbsp;
                        <li>This is the fourth term of use of our platform</li>&nbsp;
                        <li>This is the fifth term of use of our platform</li>&nbsp;
                    </ul>
                </Col>
            </Row>
        </Container>
        </div>
  );
}