import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Card, Button, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { CardBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSort, faInfoCircle, faEyeSlash, faFileAlt, faEye} from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "./TableContainer"
import { selectUser } from "../auth/authSlice";
import { selectSurveys } from "../surveylist/surveyListSlice";
import { LaunchSurveyData } from "../launchsurvey/launchSurveyData";
import { selectSurveyData } from "./surveyDataSlice";
import { FileUploads } from "./FileUploads";

export function SurveyData() {

  const user = useSelector(selectUser);
  const surveys = useSelector(selectSurveys);
  const surveydata = useSelector(selectSurveyData);
  let unsubmittedSurveys = [];
  let submittedSurveys = [];

  function byKeyLaunch(arr, key) {
    for ( var i=0, L=arr.length; i<L; i++ ) {
      if ( arr[i].SK === key ) {
        return arr[i].PIN;
      }
    }
  }

  function byKeySubmissionStatus(arr, key) {
    for ( var i=0, L=arr.length; i<L; i++ ) {
      if ( arr[i].SK === key ) {
        if (arr[i].PIN === "NONEQ")
        {
          return '-'
        }
        let submissionStatus = "Not Submitted"
          if (arr[i].eQSubmissionStatus)
          {
            for(var j=0, K=arr[i].eQSubmissionStatus.length; j<K; j++)
            {
              if(arr[i].eQSubmissionStatus[j].User === user.username)
              {
                submissionStatus = "Submitted"
                break
              }
            }
          }
          return submissionStatus
      }
    }
  }

  function byKeyStartDate(arr, key) {
    for(var i=0, L=arr.length; i<L; i++) {
      if(arr[i].SK === key) {
        if (arr[i].PINStartDate)
        {
          return arr[i].PINStartDate
        }
        else
        {
          return ""
        }
      }
    }
  }

  function byKeyEndDate(arr, key) {
    for(var i=0, L=arr.length; i<L; i++) {
      if(arr[i].SK === key) {
        if (arr[i].PINEndDate)
        {
          return arr[i].PINEndDate
        }
        else
        {
          return ""
        }
      }
    }
  }

  function dateFormat(longDate)
  {
    if(longDate && longDate !== "" && longDate!== " ")
    {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString()+
          "/"+(newDate.getMonth()+1).toString()+
          "/"+newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  } 

  const renderRowSubComponent = row => {

   const uploads = surveys.filter(obj => {
      return (obj.SK === row.original.launch) && obj.fileUploads && obj.fileUploads.length > 0
    })

    return (
      uploads.length === 0 ? (
        <div style={{margin: "0 auto", textAlign: "center"}}> No file upload data available </div>
        ) : (
        <Card>
          <CardBody>
            <FileUploads uploads={uploads[0].fileUploads} />
          </CardBody>
        </Card>
      )
    )
  }

  if (surveydata && surveydata.length > 0)
  {
    surveydata.map((surveyRow, key) => {
      if((byKeySubmissionStatus(surveys, surveyRow.SK))==="Submitted")
      {
        return submittedSurveys.push({
        id: surveyRow.author_title,
        start_date: dateFormat(byKeyStartDate(surveys, surveyRow.SK)),
        end_date: dateFormat(byKeyEndDate(surveys, surveyRow.SK)),
        launch: surveyRow.SK,
        upload: surveyRow.survey_id
        })
      }
      else
      {
        return unsubmittedSurveys.push({
        id: surveyRow.author_title,
        start_date: dateFormat(byKeyStartDate(surveys, surveyRow.SK)),
        end_date: dateFormat(byKeyEndDate(surveys, surveyRow.SK)),
        launch: surveyRow.SK,
        upload: surveyRow.survey_id
        })
      }
    }) 
  }

  const columns = useMemo(
    () => [
    {
      Header: 'Survey Title',
      accessor: 'id',
      disableFilters: true,
      disableSortBy: true
    },{
      disableFilters: true,
      disableSortBy: false,
      Header: () => (
        <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by start date
                </Popover.Content>
              </Popover>
            )} >
          <div>
            Start Date <FontAwesomeIcon icon={faSort} />
          </div>
        </OverlayTrigger>
        ),
        sortable: true,
        resizable: false, 
        accessor: 'start_date',
        Cell: props => {
            return (
                <div style={{textAlign: "left"}} >
                    {props.value}
                </div>
            )
        }
    },{
      disableFilters: true,
      disableSortBy: false,
      Header: () => (
        <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by end date
                </Popover.Content>
              </Popover>
            )} >
          <div>
            End Date <FontAwesomeIcon icon={faSort} />
          </div>
        </OverlayTrigger> ),
        sortable: true,
        resizable: false, 
        accessor: 'end_date',
        Cell: props => {
            return (
                <div style={{textAlign: "left"}} >
                    {props.value}
                </div>
            )
        }
    },{
      disableFilters: true,
      disableSortBy: true,
      Header: () => (
          <div>
            Launch
          </div>
        ),
        sortable: false,
        resizable: false,
        accessor: 'launch',
        Cell: props => {
            return (
                <div style={{textAlign: "center"}} >
                    <Button 
                        disabled={byKeyLaunch(surveys, props.value)==='NONEQ'}
                        variant="warning" 
                        onClick={() => (LaunchSurveyData([user.cso_identifier, user.username, byKeyLaunch(surveys, props.value)]))}
                        >Launch
                    </Button>
                </div>
            )
        }
    },
    {
      disableFilters: true,
      disableSortBy: true,
      Header: () => (
        <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Use this button to upload files/receipts to accompany the survey.
                  In order to add multiple files/receipts please upload a '.zip' file.
                </Popover.Content>
              </Popover>
            )} >
          <div>
            Upload Files <FontAwesomeIcon icon={faQuestionCircle} />
          </div>
        </OverlayTrigger> ),
        sortable: false,
        resizable: false,
        accessor: 'upload',
        Cell: props => {
            return (
                <div style={{textAlign: "center"}} >
                    <Link to ={`/home/upload/${props.value}`} >
                        <Button variant="secondary">Upload File</Button>
                    </Link>
                </div>
            )
        }
    },{
      Header: () => ( 
        <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  View details of previously uploaded files.
                </Popover.Content>
              </Popover>
            )} >
          <div style={{textAlign: "center"}}>
            <FontAwesomeIcon icon={faFileAlt} />
          </div>
        </OverlayTrigger> ),
      id: 'expander', // 'id' is required
      Cell: ({ row }) => (
        <div style={{textAlign: "center"}} >
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
          </span>
        </div>
      )
    }
    ], 
    [surveys, user.cso_identifier, user.username]
  )

  return (
    <div className='feature-wrapper pb-5 pt-3 mt-5 mt-lg-0'>
    <Row>
      <Col>
        <Container className= 'pt-5'>
          <Card>
            <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}>
                <h3 style={{textAlign:'left', float:'left'}}>Unsubmitted Surveys &nbsp;</h3>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 300 }}
                    overlay={(
                    <Popover id="popover-basic">
                        <Popover.Content>
                            Once a survey is submitted it may take a few minutes for the tables to update. 
                        </Popover.Content>
                    </Popover>
                    )} >
                    <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon icon={faInfoCircle}/></h5>
                </OverlayTrigger>
            </Card.Header>
            <Card.Body>
                <TableContainer columns={columns} data={unsubmittedSurveys} renderRowSubComponent={renderRowSubComponent}/>
            </Card.Body>
          </Card>
        </Container>
      </Col>
      <Col>
        <Container className= 'pt-5'>
          <Card>
            <Card.Header style={{backgroundColor: "#1D345C", color: "white"}}>
                <h3 style={{textAlign:'left', float:'left'}}>Submitted Surveys &nbsp;</h3>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 300 }}
                    overlay={(
                    <Popover id="popover-basic">
                        <Popover.Content>
                            Once a survey is submitted it may take a few minutes for the tables to update. 
                        </Popover.Content>
                    </Popover>
                    )} >
                    <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon icon={faInfoCircle}/></h5>
                </OverlayTrigger>
            </Card.Header>
            <Card.Body>
                <TableContainer columns={columns} data={submittedSurveys} renderRowSubComponent={renderRowSubComponent}/>
            </Card.Body>
          </Card>
        </Container>
      </Col>
    </Row>
    </div>
  );
}
