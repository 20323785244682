import React from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { getAuthConfig } from "../../config/auth";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setAuthState, setUser, selectUser, selectAuthState } from './authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from "react-router-dom";
import { TermsLink } from '../termsandconditions/TermsLink';
import { configureAmplifyTranslations}  from '../../i18n/translations';
import { isCookieEnabled } from '../cookiemodal/cookieSlice';

Amplify.configure(getAuthConfig());
configureAmplifyTranslations();

const AuthStateApp = () => {

    const useCookies = useSelector(isCookieEnabled);
    if(!useCookies) {
        Amplify.configure(getAuthConfig({useCookies: false}));
    }

    const user = useSelector(selectUser);
    const authState = useSelector(selectAuthState);

    const dispatch = useDispatch();

    React.useEffect(() => { 
        return onAuthUIStateChange((nextAuthState, authData) => {
            dispatch(setAuthState(nextAuthState));
            dispatch(setUser(authData));
        });
    }, [dispatch]);

    return authState === AuthState.SignedIn && user ? (
            <Redirect to="/home"/>
        ) : (
        <Container>
        <Row className='pt-5'>
            <Col></Col>
            <Col>
                <AmplifyAuthenticator >
                    <AmplifySignIn
                        slot="sign-in"
                        formFields={[
                            {
                                type: "username"
                                // label: "Username or Email *",
                                // placeholder: "Enter your username or email",
                                // required: true,
                            },
                            {
                                type: "password"
                            }
                        ]}
                    />
                    <AmplifySignUp
                        slot="sign-up"
                        formFields={[
                            {
                                type: "custom:cso_identifier",
                                label: "CSO Identifier *",
                                placeholder: "Enter your CSO identifier",
                                required: true,
                            },
                            {
                                type: "custom:PIN",
                                label: "PIN *",
                                placeholder: "Enter your PIN",
                                required: true,
                            },
                            {
                                type: "username"
                            },
                            {
                                type: "email"
                            },
                            {
                                type: "password",
                                label: "Password *",
                                placeholder: "Enter your Password",
                                hint: "Minimum 8 characters, including: 1 number, 1 uppercase letter, 1 lowercase letter, & 1 special character."
                            }
                        ]}
                        haveAccountText="By clicking Sign Up, you agree to our Terms. Please view our Terms using link below. Already have an account?"
                        submitButtonText="Sign Up"
                        headerText="Sign Up"
                    />
                </AmplifyAuthenticator>
                <div style={{float: "right"}}><TermsLink/></div>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
}

export default AuthStateApp;