import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button'
import { setAuthState, resetState } from '../auth/authSlice';
import { useDispatch } from 'react-redux';

export function Signout() {

    const [logout, setLogout] = useState(false);
    const [error] = useState(false);
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    const dispatch = useDispatch();

    async function logoutUser () {
        await setLogout(true);
        await Auth.signOut({ global: true });
        await dispatch(setAuthState(AuthState.SignedOut))
        await dispatch(resetState());
    };
    
    React.useEffect(() => {
        if(logout) {
            setIsLoggedOut(true);
        }
    }, [logout])

    if(error) {
        //TODO: show error or modal
    } else if(isLoggedOut) {
        return (
            <Redirect to="/login" />
        )
    } else {
        return (
            <div>
                <Button onClick={logoutUser} variant="primary">Sign Out</Button>
            </div>
        );
    }
}
