import { I18n } from "aws-amplify";

const enTranslations = {
    "PreSignUp failed with error 'Item'." : "CSO ID is incorrect. Please try again or contact your administrator for help.",
    "PreSignUp failed with error CSO ID or PIN is incorrect" : "Incorrect CSO ID or PIN number. Please try again or contact your administrator for help.",
    "Custom auth lambda trigger is not configured for the user pool.":"Error logging in. Ensure username and password are entered correctly. If this error persists please refresh the page.",
    "Username cannot be empty":"Username cannot be empty. If this error persists after entering your username please refresh the page and try again.",
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+" : "Username must be continuous with no spaces. Please try again or contact your administrator for help.", 
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": "Password must be at least 6 characters long and contain one of the following characters : ^+.*[\\S]+$;",
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$; Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6" : "Password must be at least 6 characters long and contain one of the following characters : ^+.*[\\S]+$;"
}

export const configureAmplifyTranslations = () => {
    I18n.putVocabulariesForLanguage("en-US", enTranslations);
    I18n.putVocabulariesForLanguage("en-UK", enTranslations);
};


