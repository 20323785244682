import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

const initialState = {
  surveydata: []
}

export const surveyDataSlice = createSlice({
  name: 'surveydata',
  initialState,
  reducers: {
    setSurveyData: (state, action) => {
      if (action.payload)
      {
        return{
          ...state,
          surveydata: [...state.surveydata, action.payload]
        }
      }
    },
    resetSurveyData: state => initialState
  },
});

export const { setSurveyData, resetSurveyData } = surveyDataSlice.actions;

export const unlistSurveyData = () => dispatch => {
              dispatch(resetSurveyData())
  };

export const listSurveyData = (surveyString) => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/query/${surveyString}`)
          .then(function(response){ //dispatch(setSurveyData(response.data.Item))
          if(response.data.Items.length === 1)
          {
            dispatch(setSurveyData(response.data.Items[0]));
          }

          });
    }
    catch(error) {
        dispatch(showError({ "message": `Survey data retrieval failed. ${error.message}` }));
    };
};

export const selectSurveyData = state => state.surveydata.surveydata;

export default surveyDataSlice.reducer;
