import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastStyles from './Messages.module.scss';
import { selectMessages, messageDelayHideTime, removeError } from './messagesSlice';
import { useSelector, useDispatch } from 'react-redux';

export function Messages() {

    const dispatch = useDispatch();
    const messages = useSelector(selectMessages);

    const toggleMessage = (key) => {
        dispatch(removeError({key}))
    }

    const toastHeaderClassNames = {
        'error' : 'errorMessageToastHeader',
        'info' : 'infoMessageToastHeader',
        'warning' : 'warningMessageToastHeader',
        'success' : 'successMessageToastHeader',
    }

    const toastBodyClassNames = {
        'error' : 'errorMessageToastBody',
        'info' : 'infoMessageToastBody',
        'warning' : 'warningMessageToastBody',
        'success' : 'successMessageToastBody',
    }

    const MessageList = (props) => {
        return props.messages.map( ( message, index ) => {
            const timeDiff = new Date() - message.key;
            if(timeDiff <= messageDelayHideTime) {
                return (
                    <Toast onClose={() => toggleMessage(message.key)} delay={messageDelayHideTime} autohide>
                        <Toast.Header className={ToastStyles[toastHeaderClassNames[message.type]]}>
                            {/* <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" /> */}
                            <strong className="mr-auto">{message.header}</strong>
                            <small>{message.sideheader}</small>
                        </Toast.Header>
                        <Toast.Body className={ToastStyles[toastBodyClassNames[message.type]]}>{message.body}</Toast.Body>
                    </Toast>
                )
            }
            else return null;
        });
    } ;

    return (
            <div style={{ position: 'absolute', top: "16em", right: "1em"}} >
                <MessageList messages={messages || []} />
            </div>
    );
}
