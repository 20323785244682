import { configureStore, combineReducers } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import cookiereducer from '../features/cookiemodal/cookieSlice';
import authReducer from '../features/auth/authSlice';
import messagesReduce from '../features/messages/messagesSlice';
import sendMessageReducer from '../features/sendmessage/sendMessageSlice';
import alertReduce from '../features/alert/alertSlice';
import removeAccountSlice from '../features/removeaccount/removeAccountSlice';
import surveyDataSlice from '../features/surveydata/surveyDataSlice';
import surveyListSlice from '../features/surveylist/surveyListSlice';

const combinedReducer = combineReducers({
    counter: counterReducer,
    cookies: cookiereducer,
    auth: authReducer,
    messages: messagesReduce,
    sendmessage: sendMessageReducer,
    alert: alertReduce,
    removeaccount: removeAccountSlice,
    surveydata: surveyDataSlice,
    surveylist: surveyListSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/resetState') { // check for action type 
    const { auth } = state;
    state = { auth };
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});
