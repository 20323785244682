import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setAuthState } from '../auth/authSlice';
import Button from 'react-bootstrap/Button';
import { AuthState } from '@aws-amplify/ui-components';
import { showError } from '../messages/messagesSlice';
import { showAlert } from '../alert/alertSlice';
import { selectTriggerRemoveAccount, deleteAccountTriggered } from './removeAccountSlice';

export function RemoveAccount() {
    const removeAccount = useSelector(selectTriggerRemoveAccount);
    const [error, setError] = useState(false);
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    const dispatch = useDispatch();

    const removeMyAccount = () => {
        dispatch(showAlert({
            title: "Warning",
            message: "Please confirm that you would like us to delete your account. Incase you are an administrator then please ensure that you re-assign your role to someone in your organisation before proceeding further.",
            closeButtonText: "Close",
            confirmButtonText: "Confirm",
            type: 'error',
            callBackActionId: 'removeaccount/deleteAccount'
        }));
    };
    
    React.useEffect(() => {
        if(removeAccount) {
            dispatch(deleteAccountTriggered());
            Auth.currentAuthenticatedUser()
            .then((user) => new Promise((resolve, reject) => {
                user.deleteUser(error => {
                    if (error) {
                        return reject(error);
                    }
                    Auth.signOut();
                    dispatch(setAuthState(AuthState.SignedOut));
                    resolve();
                });
            }))
            .then(()=>{
                setIsLoggedOut(true);
            })
            .catch((error) => {      
                setError(error);
            });
        }
    }, [removeAccount, dispatch])

    if(error) {
        dispatch(showError({ "message": "Failed to delete account. Please try again later." }));
    } else if(isLoggedOut) {
        return (
            <Redirect to="/login"/>
        )
    } else {
        return (
            <div>
                <Button onClick={removeMyAccount} variant="danger">Delete My Account</Button>
            </div>
        );
    }
}
