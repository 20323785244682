import React  from 'react';
import { useSelector } from 'react-redux';
import { RemoveAccount } from '../removeaccount/RemoveAccount';
import { Signout } from '../signout/Signout';
import { Form, Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { selectUser } from '../auth/authSlice';

export function UserProfile() {

  const user = useSelector(selectUser);

  return (
    <div>
      <Container className="pt-5">
        <Card>
          <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}><h3>User Profile</h3></Card.Header>
          <Card.Body>
          <Form>
              <Form.Group as={Row} controlId="formTextCSOIdentifier">
                <Form.Label column sm="2">
                  CSO Identifier
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" readOnly defaultValue={user.cso_identifier} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formTextUsername">
                <Form.Label column sm="2">
                  Username
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" readOnly defaultValue={user.username} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formTextEmail">
                <Form.Label column sm="2">
                  Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" readOnly defaultValue={user.email} />
                </Col>
              </Form.Group>

              <div style={{float: "right"}} className="pt-2">
                <Signout />
              </div>
              <div style={{float: "right"}} className="pt-2 pr-2">
                <RemoveAccount />
              </div>
              <div style={{float: "right"}} className="pt-2 pr-3">
                <Link to = "/home/changepwd">
                  <Button variant="info">Change My Password</Button>
                </Link>
              </div>
          </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
